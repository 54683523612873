/* Header items */
.logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 1em;
}
.ant-badge-count {
  -webkit-box-shadow: 0 0 0 0 !important;
  box-shadow: 0 0 0 0 !important;
}
.header-index-trigger {
  height: 64px;
  padding: 2px 6px;
  font-size: 24px;
  cursor: pointer;
  transition: all 0.3s, padding 0s;
  padding-left: 0px;
  margin-left: 5px;
}
.header-index-action.options:hover {
  /*transform: scale(1.05);*/
  background-color: rgb(85, 89, 105);
  cursor: pointer;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.45) inset;
}
.header-index-action:hover {
  /*transform: scale(1.05);*/
  cursor: pointer;
}
.editable-row {
  border: 1px solid rgba(169, 169, 169, 0.459);
  font: -webkit-small-control;
  border-radius: 4px;
  margin-top: 3px;
  padding: 8px 11px;
}

.header-index-logo {
  display: inline-block;
  height: 64px;
  padding: 0 0 0 24px;
  font-size: 20px;
  line-height: 64px;
  vertical-align: top;
  cursor: pointer;
  padding-left: 15px;
}
.header-index-avatar {
  margin: 20px 8px 20px 0;
  color: #1890ff;
  background: hsla(0, 0%, 100%, 0.85);
  height: 28px;
  width: 28px;
  vertical-align: bottom;
}
.header-index-right {
  float: right;
  height: 100%;
  overflow: hidden;
  margin-right: 10px;
}
.header-index-action {
  display: inline-block;
  height: 100%;
  padding: 0 10px;
  transition: transform 0.2s;
  vertical-align: bottom;
}
.header-index-header {
  position: relative;
  height: 64px;
  padding: 0;
  background: #2a2c36;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
}

/* Menu */
.ant-menu-item {
  margin-top: 0px !important;
}

/* Slider */

.sider-menu-index-logo img {
  display: inline-block;
  height: 32px;
  vertical-align: middle;
}
.sider-menu-index-logo {
  position: relative;
  height: 64px;
  padding-left: 24px;
  overflow: hidden;
  line-height: 64px;
  transition: all 0.3s;
  background: #22232b;
}

.sider-menu-index-logo h1 {
  display: inline-block;
  margin: 0 0 0 12px;
  color: #fff;
  font-weight: 600;
  font-size: 20px;
  font-family: Avenir, Helvetica Neue, Arial, Helvetica, sans-serif;
  vertical-align: middle;
}

/* Search */
.searchHeader {
  padding-top: 1.5em;
  font-weight: 500;
  font-size: 1.45em;
  /* color: "#fff"; */
  border-bottom: 1px solid rgba(169, 169, 169, 0.6);
}
/* Layout */

.ant-layout-header {
  color: whitesmoke;
  height: 64px;
  line-height: 64px;
  background: #2a2c36;
  flex: 0 0 auto;
}
.ant-layout-footer,
.ant-layout-content {
  background: #3a3f44;
  /* border-radius: .7em;
    box-shadow: 0 3px 3px 0 rgba(0,0,0,.15); */
  padding: 0.7em;
}

.ant-layout {
  min-height: -webkit-fill-available;
}

span.ant-descriptions-item-label {
  color: whitesmoke;
}

.trigger {
  font-size: 18px;
  line-height: 55px;
  padding: 0 24px;
  transition: color 0.3s;
  padding-top: 9px;
  padding-left: 15px;
  padding-right: 15px;
}

.trigger:hover {
  color: #1890ff;
  background-color: rgb(85, 89, 105);
  cursor: pointer;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.45) inset;
}

.trigger-size {
  font-size: 20px;
}
a {
  color: #1890ff;
  text-decoration: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
}
.ant-badge {
  display: inline-block;
  vertical-align: middle;
}
.index-icon {
  padding: 0.25em;
  font-size: 1em;
}

.sam-content {
  /* box-shadow: 0 2px 8px rgba(0, 0, 0, 0.45); */
  border-radius: 0.3em;
}
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}

.header-search-appear {
  opacity: 0.01;
}

.header-search-appear.header-search-appear-active {
  opacity: 1;
  transition: opacity 0.5s ease-in;
}

/* Dashboard Stuff */
.Dashboard .ant-table-title {
  background: #8c8c8c;
  border-radius: 0;
}
.Dashboard .ant-table-thead > tr:first-child > th:last-child {
  border-radius: 0;
}

.Dashboard .ant-table-thead > tr:first-child > th:first-child {
  border-radius: 0;
}

.card {
  justify-content: unset;
  padding: 10px;
}

.CostProjection {
  /* padding: 20px; */
  width: 700px;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  background: #3d4144;
  border: 1px solid #000000;
}
.Dashboard
  .antd-pro-charts-chart-card-chartCard
  .antd-pro-charts-chart-card-meta {
  height: 0;
}
.Dashboard .ant-card-body {
  padding: 10px !important;
}

.antd-pro-charts-water-wave-waterWave .antd-pro-charts-water-wave-text h4,
.antd-pro-charts-water-wave-waterWave .antd-pro-charts-water-wave-text span {
  color: white !important;
}

/* Form Changes */

.ant-form-item {
  margin: 0 0 14px !important;
}

.ant-checkbox-group {
  line-height: 2em !important;
}
