body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.ant-modal-title {
  color: white !important;
  text-align: center;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.ant-card-head-title {
  display: inline-block;
  -ms-flex: 1 1;
  flex: 1 1;
  padding: 16px 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: whitesmoke;
}
.center {
  margin: auto;
  width: 60%;
  padding: 10px;
}
.ant-select-disabled .ant-select-selection {
  background: #616161 !important;
}
.column:hover {
  cursor: pointer;
}

.ant-checkbox-disabled + span {
  color: "red" !important;
  cursor: not-allowed;
}

.text-heading {
  color: #ff8e53;
  padding-bottom: 20px;
}
.ant-layout-content {
  padding: 0 !important;
}
.ant-radio-button-wrapper-checked {
  color: white !important;
}

/* Table */
.ant-table-row-expand-icon {
  background: black !important;
}
.ant-table-row {
  color: white !important;
}
/*FixME: Azure Migration DONE - Set Table Head Text Colors to white for contrast and make visible */
.ant-table-thead > tr > th {
  color:white !important;
}

.ant-table-column-title {
  color: white;
}

.steps-content {
  margin-top: 1em;
  border-radius: 6px;
  margin-bottom: 10px;
}

.steps-content-license {
  margin-top: 1em;
  margin-bottom: 10px;
}

.ant-steps-item-title {
  color: #ffffff !important;
}

label.ant-form-item-required {
  color: #ffffff !important;
}

.steps-content button {
  background-color: #616161;
  border-color: black;
  opacity: 0.8;
  color: white;
}

.steps-content button:hover,
button:focus {
  color: #fff;
  background-color: #cb6b39;
  border-color: #2a2c36;
  opacity: 1;
}

.white-text {
  color: #fff;
}
.ant-progress-text {
  color: lightgray !important;
}
.ant-notification-notice-message {
  color: #fff !important;
  font-weight: 700;
}

.ant-steps-item-finish .ant-steps-item-title {
  color: #ffffff85 !important;
}

.ant-steps-item-wait .ant-steps-item-title {
  color: #ffffff85 !important;
}
.ant-steps-item-finish .ant-steps-item-icon {
  background-color: #ffffffb0 !important;
}
.ant-steps-item-wait .ant-steps-item-icon {
  background-color: #ffffffb0 !important;
}
.ant-steps-item-process .ant-steps-item-title {
  font-size: 18px;
}

.has-error .ant-form-explain,
.has-error .ant-form-split {
  color: #ff000d;
  text-shadow: 1px 1px #000;
}

.ant-tooltip-inner p {
  margin: 0;
}
/* This is needed as the Dot is mis-aligned without it */
.ant-steps-dot .ant-steps-item:first-child .ant-steps-icon-dot {
  left: 0px !important;
  width: 9px;
  height: 9px;
}

/* Error Pages */
.ant-result-title,
.ant-result-subtitle {
  color: white !important;
}

/* Descriptions */

.ant-descriptions-title {
  text-align: center;
  color: white !important;
}
.ant-title {
  text-align: center;
  color: #ff8e53 !important;
  font-weight: bold;
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 20px;
}
.ant-descriptions-bordered .ant-descriptions-item-label {
  background-color: #8c8c8c31 !important;
  color: white;
  width: 10em !important;
}
.icon-hover:hover {
  font-size: 20px !important;
  transition: all 0.5s !important;
}

/* Cards */
.cards {
  display: grid;
  margin: 5px 5px 0 5px;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-auto-flow: row dense;
  grid-gap: 10px;
  grid-auto-flow: dense;
  padding-left: 0px;
  overflow: hidden;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  text-align: center;
  background: #3d4144;
  /* transition: all 0.1s; */
  border: 1px solid black;
}

.card:hover:after {
  opacity: 0;
}

.card-text {
  position: relative;
  z-index: 1;
  color: rgb(39, 37, 37);
  background: whitesmoke;
  letter-spacing: 1px;
}

.card-text a {
  text-decoration: none;
}

.card-text h4 {
  margin: 0;
  padding: 0.2em;
}

/* @media screen and (min-width: 700px) {
  .card:nth-of-type(4n) {
    grid-column: span 2;
  }
  .card:nth-of-type(6n) {
    grid-column: span 2;
  }
} */

.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
  border: 1px solid #696969 !important;
}

/*FIXME: Azure Migration DONE - Dropdown select item and tag is same color as background(Text is not visible). Add Contrast colors*/
/* Dropdowns and Tags */
.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: #f36f21 !important;
}

.ant-select-selection-item-content {
 color: black;
}
