.parent {
  width: 100%;
  height: 100%;
  display: table;
  text-align: center;
  padding: 0 0;
}
.child {
  display: table-cell;
  vertical-align: middle;
  padding-top: 125px;
}
